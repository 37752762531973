import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/material';
import { toteFormSchema } from 'src/utils/schema';
import CommonSelect from 'src/components/hook_form/common_select';
import FormProvider from 'src/components/hook_form/form_provider';
import RHFTextField from 'src/components/hook_form/rhf_text_field';
import { Button, Card, InlineGrid } from '@shopify/polaris';
import { addToteItem, createRackOrTote, updateTote, useGetLocations } from 'src/api/location_api';
import { RHFAPISelect } from 'src/components/hook_form/rhf_select';
import { TOTE_TYPES } from 'src/api/common_code/location_management';
import { requiredField } from 'src/utils/format_data';
import { chooseLan } from 'src/utils/language_code';
import { LogInAlert } from 'src/components/alert/alert';
import ToteItemTableView from './table/tote_item_table_view';

const ToteForm = ({ isEdit, tote, handleViewTote, printerConfig }) => {
  const { t } = useTranslation(['settings']);
  const [errorMsg, setErrorMsg] = useState('');

  const defaultValues = useMemo(() => {
    return {
      locationId: isEdit ? tote.locationId ?? '' : '',
      toteNumber: isEdit ? tote.toteNumber ?? '' : '',
      toteType: isEdit ? tote.toteType ?? '' : '',
      description: isEdit ? tote.description ?? '' : '',
      quantity: isEdit ? tote.toteCount ?? 0 : 1,
      addTote: 0,
    };
  }, [isEdit, tote]);

  const methods = useForm({
    resolver: yupResolver(toteFormSchema(t)),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty, dirtyFields },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    setErrorMsg('');
    try {
      var res;
      if (isEdit) {
        if (dirtyFields.addTote) {
          try {
            await addToteItem({
              locationId: defaultValues.locationId,
              toteNumber: defaultValues.toteNumber,
              quantity: Number(data.addTote),
            });
          } catch (err) {
            setErrorMsg(typeof err === 'string' ? err : err.message);
          }
        }
        delete data.addTote;
        res = await updateTote(data, tote.toteNumber ?? '', tote.locationId ?? '');
      } else {
        res = await createRackOrTote(data, 'tote');
      }
      if (res.isSuccess) {
        handleViewTote();
      } else {
        setErrorMsg(res.errorMessages[0] ?? '');
      }
    } catch (error) {
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });

  const renderFormContent = (
    <Card>
      <Stack spacing={1}>
        {!!errorMsg && (
          <LogInAlert
            onClose={() => setErrorMsg('')}
            errorMsg={errorMsg}
          />
        )}
        <InlineGrid
          columns={3}
          gap="200"
        >
          <RHFAPISelect
            name="locationId"
            displayName={requiredField(t('common:field.location'))}
            toOption={(r) => ({
              key: r.locationId,
              value: chooseLan(r.locationNameLocal, r.locationNameEnglish),
            })}
            useOptions={useGetLocations}
            disabled={isEdit}
            params={{
              pageNumber: 1,
              pageSize: 100,
              isActive: true,
            }}
          />
          <RHFTextField
            name="toteNumber"
            disabled={isEdit}
            displayName={requiredField(t('common:field.tote_number'))}
          />
          <CommonSelect
            data={TOTE_TYPES}
            name="toteType"
            displayName={t('common:field.tote_type')}
          />
        </InlineGrid>
        <InlineGrid
          columns={2}
          gap="200"
        >
          <RHFTextField
            name="quantity"
            disabled={isEdit}
            displayName={requiredField(t('common:field.tote_quantity'))}
            type="number"
            InputProps={{ inputProps: { min: 1, max: 1000 } }}
          />
          <RHFTextField
            name="description"
            displayName={t('common:field.tote_description')}
          />
        </InlineGrid>
        {isEdit && (
          <RHFTextField
            name="addTote"
            displayName={t('common:field.tote_quantity_to_add')}
          />
        )}
      </Stack>
    </Card>
  );

  const renderForm = (
    <FormProvider
      methods={methods}
      onSubmit={onSubmit}
    >
      <Stack spacing={2}>
        {renderFormContent}
        {isEdit && (
          <Card>
            <ToteItemTableView
              selectedLocation={tote.locationId}
              selectedTote={tote.toteNumber}
              printerConfig={printerConfig}
            />
          </Card>
        )}
      </Stack>

      <Box
        width={'100%'}
        display="flex"
        justifyContent={'flex-end'}
        columnGap={'12px'}
        marginTop="12px"
      >
        <Button
          loading={isSubmitting}
          onClick={handleViewTote}
        >
          {t('common:cancel')}
        </Button>
        <Button
          submit
          loading={isSubmitting}
          variant="primary"
          disabled={!isDirty}
        >
          {isEdit ? t('common:save') : t('common:create_and_print')}
        </Button>
      </Box>
    </FormProvider>
  );

  return renderForm;
};
export default ToteForm;
