import { useFormContext, Controller } from 'react-hook-form';
import { Box, InputAdornment, InputLabel, TextField, Typography } from '@mui/material';
import { inputStyles } from 'src/utils/style';

// ----------------------------------------------------------------------

export default function RHFTextField({
  styles,
  name,
  helperText,
  type,
  displayName,
  sx,
  disabled = false,
  startAdornment = null,
  inventoryAdornment = false,
  ...other
}) {
  const { control } = useFormContext();
  const disabledStyles = disabled
    ? {
        '& .MuiOutlinedInput-root': {
          ...inputStyles['& .MuiOutlinedInput-root'],
          backgroundColor: 'var(--p-color-bg-surface-disabled)',
          '& fieldset': {
            borderColor: 'var(--p-color-bg-surface-disabled) !important',
          },
        },
      }
    : {};

  const handleNumberChange = (event, field) => {
    const value = event.target.value;
    if (value === '' || !value.includes('.')) {
      field.onChange(value === '' ? '' : Number(value));
    } else {
      const decimalPart = value.split('.')[1];
      if (decimalPart.length <= 3) {
        field.onChange(Number(value));
      } else {
        const truncatedValue = `${value.split('.')[0]}.${decimalPart.slice(0, 3)}`;
        field.onChange(Number(truncatedValue));
      }
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box sx={{ width: '100%', ...styles }}>
          <InputLabel>
            <Typography variant="body_md">{displayName}</Typography>
          </InputLabel>
          <TextField
            {...field}
            sx={{ ...inputStyles, ...sx, ...disabledStyles }}
            fullWidth
            type={type}
            value={field.value}
            onChange={(event) => {
              if (type === 'number') {
                handleNumberChange(event, field);
              } else {
                field.onChange(event.target.value);
              }
            }}
            InputProps={{
              steps: 0.001,
              startAdornment: startAdornment && (
                <InputAdornment position={`${inventoryAdornment ? '' : 'start'}`}>
                  {startAdornment}
                </InputAdornment>
              ),
            }}
            error={!!error}
            helperText={error ? error?.message : helperText}
            size={'small'}
            variant={disabled ? 'filled' : 'outlined'}
            disabled={disabled}
            {...other}
          />
        </Box>
      )}
    />
  );
}
